import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

function useGsapContext(
  contextFunction: gsap.ContextFunc,
  scope?: Element | string | object
) {
  const context = ref<gsap.Context | null>(null)

  onMounted(() => {
    context.value = gsap.context(contextFunction, scope)
  })

  onUnmounted(() => context.value?.revert())

  return { context }
}

export default useGsapContext
